import $ from 'jquery';

$(() => {
  const $tokenPrice = $('#token-price');
  $.ajax({
    url: "https://api.inbest.today/api/price"
  }).done(function(data) {
    $tokenPrice.html(parseFloat(data).toFixed(4));
  });
})
