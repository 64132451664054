import $ from 'jquery'

$(() => {

  function register($form) {
    $('#HeroFormResponseSuccess').text('');
    $('#HeroFormResponse').text('');
    $.ajax({
      type: "GET",
      url: $form.attr('action'),
      data: $form.serialize(),
      cache: false,
      dataType: 'json',
      contentType: "application/json; charset=utf-8",
      error: function (err) {
        console.log('error')
      },
      success: function (data) {
        if (data.result != "success") {
          $('#HeroFormResponseSuccess').text('Success!!!');
          $('#mce-EMAIL').val('');
        } else {
          console.log("Success");
          $('#HeroFormResponse').text(data.msg);
        }
      }
    });
  }

  $('#HeroForm').on('submit', function (event) {
    var $form = $(this);
    event.preventDefault();
    register($form);
  });

});
