// Main javascript entry point
// Should handle bootstrapping/starting application
'use strict';

// Modules
import '../_modules/Header/Header';
import '../_modules/Slideshow/Slideshow';
import '../_modules/Footer/Footer';
import '../_modules/ContactModal/ContactModal';
import '../_modules/SideMenu/SideMenu';
import '../_modules/Register/Register';
