import $ from 'jquery'

$(() => {

  const $window = $(window)
  const $header = $('.header');
  const $headerMenu = $('.header .menu')
  const $menu = $header.find('.menu')
  const $navicon = $('.navicon')
  const $sideMenu = $('.side-menu')
  const $mask = $('.mask-overlay')
  const $contactBtn = $('.menu a.Button')
  const $contactSideMenu = $('.menu-links a:last-child');
  const $contactModal = $('.contact-modal')
  const $closeIcon = $('.close-icon');
  const $firstStep = $('.first-step');
  const $success = $('.success');
  const $input = $('.group input');
  const $dropdown = $('.dropdown');
  const $dropdownLabel = $('.dropdown-label');

  let scrolled = [$window.scrollTop()];

  $window.on('scroll resize', () => {
    const scrollTop = $window.scrollTop()

    $header.toggleClass('visible', scrolled[0] >= scrolled[scrolled.length - 1])
    $header.toggleClass('fixed', scrollTop >= 120)

    if (scrolled.length >= 25) {
      scrolled.shift()
    }
    scrolled.push(scrollTop)
  })

  $window.triggerHandler('resize')

  $navicon.on('click', (event) => {
    event.preventDefault()
    $header.toggleClass('menu-opened')
    $sideMenu.toggleClass('active');
    $mask.toggleClass('active')
  })

  $mask.on('click', function(event) {
    $sideMenu.removeClass('active')
    $(this).removeClass('active')
    $contactModal.removeClass('active')
  })

  $headerMenu.on('click', 'a[href^="#"]', function (event) {
    console.log('clicl aca');
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
  });

  $contactBtn.on('click', function(event) {
    $contactModal.toggleClass('active')
    $mask.toggleClass('active')
    clearForm();
  })

  $contactSideMenu.on('click', function() {
    $contactModal.toggleClass('active')
    $sideMenu.toggleClass('active')
    clearForm();
  })

  $closeIcon.on('click', function() {
    $contactModal.toggleClass('active')
    $mask.toggleClass('active')
  })

  function clearForm() {
    $firstStep.removeClass('hidden')
    $success.removeClass('active')
    $input.val('')
    $input.removeClass('has-value')
    $dropdown.prop('selectedIndex',0)
    $dropdownLabel.removeClass('has-value')
  }
});
