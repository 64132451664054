import $ from'jquery';

const $input = $('.group input');
const $dropdownLabel = $('.dropdown-label');
const $submit = $('.submit');
const $firstStep = $('.first-step');
const $name = $('.name span');
const $success = $('.success');

$(() => {
  $input.focusout(function(){
    let textVal = $(this).val();
    const $nameInput = $('#mce-FNAME').val();
    $name.text($nameInput);
    if (textVal === '') {
      $(this).removeClass('has-value');
    } else {
      $(this).addClass('has-value');
    }

    if( $('#mce-EMAIL').hasClass('mce_inline_error') ) {
      $submit.prop('disabled', true);
    } else {
      $submit.prop('disabled', false);
    }
  });

  $('select').on('change', function() {
    if($(this).val() === '') {
      $dropdownLabel.removeClass('has-value');
    } else {
      $dropdownLabel.addClass('has-value');
    }
  });

  $submit.on('click', function() {
    $firstStep.addClass('hidden');
    $success.addClass('active');
  });
});