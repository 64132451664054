import $ from 'jquery';

$(() => {
  const $form = $('.newsletter');
  const $submitButton = $form.find('.Button.submit');
  const $label = $form.find('.result');
  
  $submitButton.on('click', function (event) {
    event.preventDefault();
    $form.trigger('submit');
  });
  
  $form.on('submit', function(event) {
    event.preventDefault();

    $.ajax({
      url: $form.attr('action'),
      data: $form.serialize(),
      dataType: 'jsonp',
      complete: (response) => {
        $label.text(response.responseJSON.msg);
      }
    });
  });
});
