import $ from 'jquery';
import 'slick-carousel';

$(() => {

  $('.slideshow .list').slick({
    dots: true,
    arrows: false,
    speed: 200,
    slidesToShow: 1,
    mobileFirst: true,
    centerPadding: '5px',
    responsive: [
      {
        breakpoint: 1023,
        settings: 'unslick'
      }
    ]
  });

});
